/**
 * 谷歌地图工具类
 */
import {Loader} from "@googlemaps/js-api-loader";

const loader = new Loader({
  apiKey: "AIzaSyAzGZqx5qLMGQ4dMpqRdNhKh0sarjFHMXA",
  version: "weekly",
  libraries: ["places"],
});
const initMap = function(id, zoom, centerLng, centerLat) {
  return new Promise((resolve, reject) => {
    loader.load().then(() => {
      let map = new window.google.maps.Map(document.getElementById(id), {
        center: {lat: centerLat || 30, lng: centerLng || 93},
        zoom: zoom || 3,
        fullscreenControl: false,
        draggableCursor: "default",
      });
      resolve(map);
    }).catch(err => reject(err));
  });
};

export default {initMap};
