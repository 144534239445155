<template>
  <el-dialog
    :title="title"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="1300px"
    top="2vh"
    @close="onDialogClose">
    <div v-loading="contentLoading" class="body">
      <el-form
        ref="formValidate"
        class="form"
        label-width="80px"
        :model="useUnit"
        :rules="ruleValidate">
        <el-row :gutter="20">
          <el-col :span="9">
            <div>
              <el-form-item :label="$t('useUnit.name')" prop="name">
                <el-input v-model="useUnit.name" :placeholder="$t('common.pleaseEnter')"></el-input>
              </el-form-item>
              <el-form-item :label="$t('useUnit.buildingCount')" prop="buildingCount">
                <el-input
                  v-model="useUnit.buildingCount"
                  type="number"
                  :placeholder="$t('common.pleaseEnter')"></el-input>
              </el-form-item>
              <el-form-item :label="$t('useUnit.districtId')" prop="districtId">
                <vm-district-select v-model="useUnit.districtId" @clear="useUnit.districtId = 0"></vm-district-select>
              </el-form-item>
              <el-form-item :label="$t('useUnit.address')" prop="address">
                <el-input v-model="useUnit.address"></el-input>
              </el-form-item>
              <el-form-item :label="$t('useUnit.unitCode')" prop="manufacturerCode">
                <el-input v-model="useUnit.manufacturerCode" :placeholder="$t('common.pleaseEnter')"></el-input>
              </el-form-item>
              <el-form-item :label="$l('useUnit.lat','经纬度')" :rules="$rule.notNull">
                <div class="vm-separate">
                  <el-form-item prop="lng" style="margin-right: 10px;margin-bottom: 0">
                    <el-input v-model="useUnit.lng" :placeholder="$t('useUnit.lng')" readonly></el-input>
                  </el-form-item>
                  <el-form-item prop="lat" style="margin-left: 10px;margin-bottom: 0">
                    <el-input v-model="useUnit.lat" :placeholder="$t('useUnit.lat')" readonly></el-input>
                  </el-form-item>
                </div>
              </el-form-item>
              <el-form-item :label="$t('useUnit.person')" prop="person">
                <el-input v-model="useUnit.person" :placeholder="$t('common.pleaseEnter')"></el-input>
              </el-form-item>
              <el-form-item :label="$t('useUnit.contactTel')" prop="phone">
                <el-input v-model="useUnit.phone" :placeholder="$t('common.pleaseEnter')"></el-input>
              </el-form-item>
              <div v-if="userEditAuth">
                <el-divider content-position="left">用户信息</el-divider>
                <el-form-item :label="$t('user.username')" prop="username">
                  <el-input
                    v-model="useUnit.username"
                    :placeholder="$t('common.pleaseEnter')"
                    auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item :label="$t('user.password')" prop="password">
                  <el-input
                    v-model="useUnit.password"
                    type="text"
                    :class="isShowPassword ? '' : 'displayPass'"
                    :placeholder="$t('common.pleaseEnter')"
                    autocomplete="new-password"></el-input>
                  <i
                    class="el-icon-view"
                    :class="{ icon: isShowPassword }"
                    style="position: absolute;right: 8px;top: 8px;color: #e6e6e6"
                    @click="show"></i>
                </el-form-item>
                <el-form-item :label="$t('user.name')" prop="personName">
                  <el-input v-model="useUnit.personName " :placeholder="$t('common.pleaseEnter')"></el-input>
                </el-form-item>
                <el-form-item :label="$t('user.phone')" prop="phone">
                  <el-input
                    v-model="useUnit.contactTel"
                    :placeholder="$t('common.pleaseEnter')"
                    :maxlength="20"></el-input>
                </el-form-item>
                <el-form-item :label="$t('user.type')" prop="userType">
                  <el-select v-model="useUnit.userType" style="width: 100%">
                    <el-option
                      v-for="item in userTypes"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="是否维保工" prop="isMaintWorker">
                  <el-select v-model="useUnit.isMaintWorker" style="width: 100%">
                    <el-option :label="$t('common.yes')" :value="1"></el-option>
                    <el-option :label="$t('common.no')" :value="0"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="对应角色" prop="roleIds">
                  <el-select
                    v-model="useUnit.roleIds"
                    multiple
                    :placeholder="$t('common.pleaseSelect')"
                    style="width: 100%"
                    clearable>
                    <el-option
                      v-for="item in roleList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
          </el-col>
          <el-col :span="15">
            <vm-bmap v-if="$i18n.isCn" ref="map" @lat-lng-change="latLngChange"></vm-bmap>
            <vm-gmap v-else ref="map" @lat-lng-change="latLngChange"></vm-gmap>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">{{$t("common.cancel")}}</el-button>
      <el-button type="primary" :loading="submitLoading" :disabled="saveDisabled" @click="handleSubmit">
        {{$t("common.save")}}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
  import VmDistrictSelect from "@/components/VmDistrictSelect";
  import VmBmap from "@/components/VmBmap";
  import VmGmap from "@/components/VmGmap";
  import auth from "@/util/auth";

  export default {
    components: {VmDistrictSelect, VmBmap, VmGmap},
    data() {
      return {
        userEditAuth: this.$auth(2),
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        saveDisabled: false,
        searchFilter: "",
        searchValue: "",
        local: {},
        myCenter: "",
        geoCoder: "",
        userTypes: [],
        roleList: [],
        isShowPassword: false,
        useUnit: {
          id: 0,
          name: "",
          manufacturerCode: "",
          type: "",
          districtId: 0,
          address: "",
          buildingCount: "",
          phone: "",
          person: "",
          lng: "",
          lat: "",
          username: "",
          password: "",
          personName: "",
          contactTel: "",
          roleIds: [],
          userType: "",
          isMaintWorker: "",
        },
        ruleValidate: {
          name: {required: true, message: this.$t("useUnit.tip.name"), trigger: "blur"},
          lng: {required: true, message: this.$t("useUnit.tip.lng"), trigger: "change"},
          lat: {required: true, message: this.$t("useUnit.tip.lat"), trigger: "change"},
        },
      };
    },
    computed: {
      title() {
        return (this.useUnit.id === 0 ? this.$t("common.add") : this.$t("common.edit")) + " " + this.$t("route.useUnit");
      },
    },
    methods: {
      open(id) {
        this.dialogVisible = true;
        this.useUnit.id = id;
        this.initUserTypes();
        this.getRoles();
        if (this.useUnit.id > 0) {
          this.getData();
        } else {
          this.useUnit.districtId = -1;
          this.initMap();
        }
      },
      show() {
        this.isShowPassword = !this.isShowPassword;
      },
      initUserTypes() {
        let userType = [];
        let types = [];
        if (auth.getUsername() === "admin" || auth.getUsername() === "superAdmin") {
          types = [0, 1, 9];
        } else if (auth.getUserType() === 1) { //企业管理员可以建立企业管理员与企业普通用户
          types = [1, 2];
        } else {//企业普通用户只能建立企业普通用户
          types = [2];
        }
        for (let type of types) {
          let item = {
            value: type,
            label: this.$t("user.types." + type),
          };
          userType.push(item);
        }
        this.userTypes = userType;
      },
      getRoles() {
        if (this.roleList.length === 0) { // 此时需要获取角色列表
          let params = {
            pageSize: 1000,
          };
          this.$api.getList("roles/new", params).then(res => {
            this.roleList = res.data.records;
          });
        }
      },
      getData() {
        this.$api.getById("companies", this.useUnit.id).then(res => {
          Object.assign(this.useUnit, res.data);
          if (this.useUnit.districtId == 0) {
            this.useUnit.districtId = -1;
          }
          this.initMap(this.useUnit.lng, this.useUnit.lat);
        });
      },
      initMap(centerLng, centerLat) {
        this.$nextTick(() => {
          this.$refs.map.initMap(centerLng, centerLat);
        });
      },
      latLngChange(point) {
        this.useUnit.lng = point.lng;
        this.useUnit.lat = point.lat;
      },
      onDialogClose() {
        this.saveDisabled = false;
        this.contentLoading = false;
        this.dialogVisible = false;
        this.useUnit.parentName = "";
        this.useUnit.contactTel = "";
        this.$refs.formValidate.resetFields();
      },
      handleSubmit() {
        if (this.useUnit.username && this.useUnit.password) {
          if (this.useUnit.username) {
            let rule = /^[a-zA-Z0-9_@.]{4,25}$/;
            if (!rule.test(this.useUnit.username)) {
              return this.$message.error(this.$l("user.userNameRule", "账号为4~25位字符，只能包含英文字母、数字、@、点、下划线"));
            }
          }
          if (this.useUnit.password) {
            let rule = /^(?![A-Za-z]+$)(?![A-Z\d]+$)(?![A-Z\W]+$)(?![a-z\d]+$)(?![a-z\W]+$)(?![\d\W]+$)\S{8,20}$/;
            if (!rule.test(this.useUnit.password)) {
              return this.$message.error("密码为8~30位字符，必须包含大写字母、小写字母、数字");
            }
          }
          if (!this.useUnit.personName) {
            this.$message.error("姓名不能为空");
            return;
          }
          if (!this.useUnit.contactTel) {
            this.$message.error("手机号不能为空");
            return;
          } else {
            let rule = /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|19[0-9]|14[57])[0-9]{8}$/;
            if (!rule.test(this.useUnit.contactTel)) {
              return this.$message.error("请输入正确的手机号");
            }
          }
          if (this.useUnit.userType === "") {
            this.$message.error("用户类型不能为空");
            return;
          }
          if (this.useUnit.isMaintWorker === "") {
            this.$message.error("是否维保工不能为空");
            return;
          }
          if (this.useUnit.roleIds.length === 0) {
            this.$message.error("对应角色不能为空");
            return;
          }
        }
        this.$refs.formValidate.validate((valid) => {
          if (valid) {
            this.submitLoading = true;
            this.useUnit.type = 8;
            this.$api.save("companies", this.useUnit).then(res => {
              this.submitLoading = false;
              this.dialogVisible = false;
              this.$emit("save-success", this.useUnit.id === 0);
              this.$message.success(this.$t("common.tip.saveSuccess"));
            }).catch(error => {
              this.submitLoading = false;
              this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
            });
          } else {
            this.$message.error(this.$t("common.tip.submitError") + "!");
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
.displayPass {
  //将文本替换为指定的字符
  -webkit-text-security: disc;
}

@import "../../../style/variables";

#map {
  width: 100%;
  height: 612px;
}
</style>
